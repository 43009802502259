jQuery(document).ready(function(){
    openClose();

    $('.simplebox').simplebox({
        overlay: {
            color: '#999999',
            opacity: 0.8
        },
        linkClose: '.close-modal'
    });
    if($('.autostart-modal').length > 0){
        $.simplebox('.autostart-modal', {
            overlay: {
                color: '#999999',
                opacity: 0.8
            },
            linkClose: '.close-modal'
        });
    };
    OpenBox({
        wrap: '.open-box',
        link: '.drop-btn',
        box: '.open-drop',
        close: '.close',
        openClass: 'open'
    });
    OpenBox({
        wrap: '.filter-list > li',
        link: '.filter-item',
        box: '.sub-list',
        openClass: 'open'
    });
    OpenBox({
        wrap: '.open-details',
        link: '.details-btn',
        box: '.details-box',
        close: '.close',
        openClass: 'open'
    });
    $('.basic-tabs').tabs();

    searchFaq();
    scrolledAnchor();
});

function openClose() {
    var wrap = $('.open-close').each(function () {
        var boxed = $(this);
        var link = boxed.find('.open-close-btn');
        var boxOpen = boxed.find('.open-close-drop');

        boxOpen.css('display', 'none');
        boxed.removeClass('open')
        link.on('click', function (e) {
            if (!boxed.hasClass('open')) {
                boxed.addClass('open');
                boxOpen.slideUp(0).slideDown(400);
            } else {
                boxOpen.slideUp(400, function () {
                    boxed.removeClass('open');
                });
            }
            return false;
        });
    });
};
function OpenBox(obj) {
    $(obj.wrap).each(function () {
        var hold = $(this);
        var link = hold.find(obj.link);
        var box = hold.find(obj.box);
        var w = obj.w;
        var close = hold.find(obj.close);

        link.click(function () {
            $(obj.wrap).not(hold).removeClass(obj.openClass);
            if (!hold.hasClass(obj.openClass)) {
                hold.addClass(obj.openClass);
            } else {
                hold.removeClass(obj.openClass);
            }
            return false;
        });
        hold.hover(function () {
            $(this).addClass('hovering');
        }, function () {
            $(this).removeClass('hovering');
        });
        $("body").click(function () {
            if (!hold.hasClass('hovering')) {
                hold.removeClass(obj.openClass);
            }
        });
        close.click(function () {
            hold.removeClass(obj.openClass);
            return false;
        });
        $(document).bind('touchstart mousedown', function (e) {
            if (!($(e.target).parents().index(hold) != -1 || $(e.target).index(hold) != -1)) {
                hold.removeClass(obj.openClass);
            }
        });
    });
};
function scrolledAnchor(){
    var menu_selector = ".faq-menu"; // Переменная должна содержать название класса или идентификатора, обертки нашего меню.
    var menu = 0;
    jQuery(".faq-menu ul li:first-child a").addClass("current");

    function onScroll(){
        var scroll_top = jQuery(document).scrollTop();

        jQuery(menu_selector + " a").each(function(){
            var hash = jQuery(this).attr("href");
            var target = jQuery(hash);
            if (target.position().top - menu <= scroll_top && target.position().top + target.outerHeight()  > scroll_top) {
                jQuery(menu_selector + " a.current").removeClass("current");
                jQuery(this).addClass("current");
            } else {
                jQuery(this).removeClass("current");
            }
        });
    }
    jQuery(".faq-modal").on("scroll", onScroll);

    jQuery(".faq-menu ul li a").click(function(e){
        e.preventDefault();

        jQuery(document).off("scroll");
        jQuery(menu_selector + " .current").removeClass("current");
        jQuery(this).addClass("current");
        var hash = jQuery(this).attr("href");
        var target = jQuery(hash);
        jQuery("html, body").animate({
            scrollTop: target.offset().top
        }, 300, function(){
            window.location.hash = hash;
            jQuery(document).on("scroll", onScroll);

        });
    });
};
function searchFaq() {
    document.querySelectorAll('.faq-modal').forEach(function(hold){
        var input = hold.querySelector(".search-faq-input");
        var li = hold.querySelectorAll(".faq-body .question");
        input.addEventListener('keyup', function(e){
            var filter = input.value.toUpperCase();

            for (var i = 0; i < li.length; i++) {
                var txtValue = li[i].textContent || li[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].parentNode.style.display = "";
                } else {
                    li[i].parentNode.style.display = "none";
                }
            };
        });
    });
};